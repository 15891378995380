$(document).ready(() => {
    let $showPassword = $('.show-password')
    $showPassword.on('mouseover', function() {
        changeType($(this).prev('input'), 'text');
    });

    $showPassword.on('mouseout', function() {
        changeType($(this).prev('input'), 'password');
    });

    function changeType(x, type) {
        if(x.prop('type') === type)
            return x; //That was easy.
        try {
            return x.prop('type', type); //Stupid IE security will not allow this
        } catch(e) {
            //Try re-creating the element (yep... this sucks)
            //jQuery has no html() method for the element, so we have to put into a div first
            let html = $("<div>").append(x.clone()).html();
            let regex = /type=(\")?([^\"\s]+)(\")?/; //matches type=text or type="text"
            //If no match, we add the type attribute to the end; otherwise, we replace
            let tmp = $(html.match(regex) == null ?
                html.replace(">", ' type="' + type + '">') :
                html.replace(regex, 'type="' + type + '"') );
            //Copy data from old element
            tmp.data('type', x.data('type') );
            let events = x.data('events');
            let cb = function(events) {
                return function() {
                    //Bind all prior events
                    for(let i in events)
                    {
                        let y = events[i];
                        for(j in y)
                            tmp.bind(i, y[j].handler);
                    }
                }
            }(events);
            x.replaceWith(tmp);
            setTimeout(cb, 10); //Wait a bit to call function
            return tmp;
        }
    }

    $('#btnCopyPassword').on('click', (event) => {
        event.preventDefault();
        const $password = $('#btnCopyPassword').parents('.input-password').find('#password')
        // changeType( $password, 'text');
        let myPassword = $password.val();
        // changeType( $password, 'password');
        const element = document.createElement('textarea');
        element.value = myPassword;
        // Add it to the document so that it can be focused.
        document.body.appendChild(element);
        // Focus on the element so that it can be copied.
        element.focus();
        element.setSelectionRange(0, element.value.length);
        // Execute the copy command.
        document.execCommand('copy');
        // Remove the element to keep the document clear.
        document.body.removeChild(element);
        $('#btnCopyPassword').addClass('ok').val('OK');
        setTimeout(() =>{
            $('#btnCopyPassword').removeClass('ok').val('Copier');
        }, 1000)
    })
})
