if($('#formRenovationEtape2').length){
    $('#formRenovationEtape2 #submitNew').on('click', (event) => {
        event.preventDefault();
        let $form = $("#formRenovationEtape2");
        let msg = "";
        let longueurMin = 40;
        let largeurMin = 40;
        let hauteurMin = 40;
        let longueur = new Number($form.find('.inputLongueur').val());
        let largeur = new Number($form.find('.inputLargeur').val());
        let hauteur = new Number($form.find('.inputHauteur').val());
        let hauteurRetombee = new Number($form.find('.inputHautRetombee').val());
        let hauteurPlinthes = new Number($form.find('.inputHautPlinthes').val());
        let nbPFen = new Number($form.find('.inputPFen').val());
        let nbPorte = new Number($form.find('.inputPorte').val());
        let nbFenetre = new Number($form.find('.inputFenetre').val());
        let nbRadiateur = new Number($form.find('.inputRadiateur').val());
        let checkPFen = $('[data-poste="nb-porte-fenetre"]').is(':checked');
        let checkFenetre = $('[data-poste="nb-fenetre"]').is(':checked');
        let checkPorte = $('[data-poste="nb-porte"]').is(':checked');
        let checkRad = $('[data-poste="nb-radiateur"]').is(':checked');
        let checkPlinthes = $('[data-poste="plinthes"]').is(':checked');
        let checkPlaf = $('[data-poste="plafonds"]').is(':checked');
        let checkMurs = $('[data-poste="murs"]').is(':checked');
        let checkSols = $('[data-poste="sols"]').is(':checked');
        let checkCorniches = $('[data-poste="corniches"]').is(':checked');
        let checkGalons = $('[data-poste="galons"]').is(':checked');
        let roomname = !$('[data-poste="room-name"]').val();
        let roomtype = $('[data-poste="room-type"]').val();
        let lastrenov = $('[data-poste="lastrenov"]').val();



        
        if(roomname) msg += "Le nom de la pièce doit obligatoirement être renseigné.\n";
        if(roomtype == '') msg +="Le type de la pièce doit être renseigné.\n";
        if(lastrenov == '') msg +="La date de rénovation de la pièce doit être renseignée.\n";
        
        /*** La longueur ne peut être inférieure à la valeur minimale ***/
        if(longueur < longueurMin) msg += "La longueur de la pièce ne peut être inférieure à 40cm.\n";

        /*** La largeur ne peut être inférieure à la valeur minimale ***/
        if(largeur < largeurMin) msg += "La largeur de la pièce ne peut être inférieure à 40cm.\n";

        /*** La longueur ne peut être inférieure à la valeur minimale ***/
        if(hauteur < hauteurMin) msg += "La hauteur de la pièce ne peut être inférieure à 40cm.\n";

        /*** La largeur doit être inférieur à la longueur ***/
        if(longueur < largeur) msg += "La largeur de la pièce ne peut être supérieure à la longueur.\n";
        if (longueur < 0) msg += "La longueur ne peut pas être négative!\n";
        if (largeur < 0) msg += "La largeur ne peut pas être négative!\n";
        if (hauteur < 0) msg += "La hauteur ne peut pas être négative!\n";
        if (hauteurRetombee < 0) msg += "La hauteur de la retombée ne peut pas être négative!\n";
        if (hauteurPlinthes < 0) msg += "La hauteur des plinthes ne peut pas être négative!\n";
        /*** Les hauteurs retombee et plinthes ne peuvent être supérieures à la hauteur de plafond ***/
        if(hauteurRetombee >= hauteur && hauteur > 0)
            msg += "La hauteur de la retombée de plafond ne peut être supérieure à la  hauteur de la pièce.\n";
        if(hauteurPlinthes >= hauteur && hauteur > 0)
            msg +="La hauteur des plinthes ou de carrelage mural ne peut être supérieure à la hauteur de la pièce.\n";
        if (checkSols && (longueur == 0 || largeur == 0))
            msg += "Vous devez renseigner les dimensions de la pièce.";
        /*** Si Murs coché et hauteur mur == 0 ***/
        if(checkMurs && hauteur == 0)
            msg += 'La hauteur des murs doit être supérieure à 0\n';
        /*** Si Murs coché et calcul hauteur mur <= 0 ***/
        if(checkMurs && hauteur != 0 && hauteur <= (hauteurPlinthes + hauteurRetombee))
            msg += 'La hauteur de plinthes ou carrelage mural ajoutée à la retombée de plafond est égale ou supérieure à la hauteur du mur. La retombée de plafond est calculée dans le plafond. Vous n\'avez pas besoin de sélectionner "Murs" dans ce cas là.\n';
        /*** IL NE PEUT PAS Y AVOIR DE TRAVAUX SUR DES PORTES-FENETRES SI LE NOMBRE DE PORTES-FENETRES = 0 ***/
        if(checkPFen && nbPFen == 0)
            msg += "Vous souhaitez réaliser des travaux sur des portes-fenêtres mais le nombre total de vos portes-fenêtres est égal à 0 !\n";
        if(nbPFen < 0)
            msg += "Le nombre de portes-fenêtres ne peut pas être négatif!\n";        /*** IL NE PEUT PAS Y AVOIR DE TRAVAUX SUR DES FENETRES SI LE NOMBRE DE FENETRES = 0 ***/
        if(checkFenetre && nbFenetre == 0)
            msg += "Vous souhaitez réaliser des travaux sur des fenêtres mais le nombre total de vos fenêtres est égal à 0 !\n";
        if(nbFenetre < 0)
            msg += "Le nombre de fenêtre ne peut pas être négatif!\n";
        /*** IL NE PEUT PAS Y AVOIR DE TRAVAUX SUR DES PORTES SI LE NOMBRE DE PORTES = 0 ***/
        if(checkPorte && nbPorte == 0)
            msg += "Vous souhaitez réaliser des travaux sur des portes mais le nombre total de vos portes est égal à 0 !\n";
        if(nbPorte < 0)
            msg += "Le nombre de porte ne peut pas être négatif!\n";
        /*** IL NE PEUT PAS Y AVOIR DE TRAVAUX SUR DES RADIATEURS SI LE NOMBRE DE RADIATEURS = 0 ***/
        if(checkRad && nbRadiateur == 0)
            msg += "Vous souhaitez réaliser des travaux sur des radiateurs mais le nombre total de vos radiateurs est égal à 0 !\n";
        if(nbRadiateur < 0)
            msg += "Le nombre de radiateur ne peut pas être négatif!\n";
        /*** IL NE PEUT PAS Y AVOIR DE TRAVAUX SUR DES PLINTHES OU SUR DU CARRELAGE MURAL SI LE NOMBRE = 0 ***/
        if(checkPlinthes && hauteurPlinthes == 0)
            msg += "Vous souhaitez réaliser des travaux sur des plinthes ou du carrelage mural mais la hauteur est égal à 0!\n";
        if(checkPlinthes && hauteurPlinthes < 0)
            msg += "La hauteur de plinthe ne peut pas être négative!\n";
        /*** on vérifie qu'il y ait au moins une rénovation à deviser ***/
        if(!(checkPlaf || checkMurs || checkSols || checkPorte || checkFenetre || checkPFen || checkRad || checkCorniches || checkGalons || checkPlinthes))
            msg += "Aucune rénovation à estimer. Veillez à choisir au moins un état à rénover.";

        if(msg !== ""){
            swal({
                title: 'Un petit problème',
                text: msg,
                icon : 'error',
                className: "modal-error-devis"
            });
        }
        else {
            $form.submit();
        }
    });
}
