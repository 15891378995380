// require("expose-loader?$!jquery");
//import $ from "jquery";
import swal from "sweetalert";
// require("expose-loader?swal!sweetalert");

import Foundation from 'foundation-sites';

import '../modules/menubar'
import '../modules/devisinstant'
import '../modules/password'
import '../modules/validation'
import '../modules/scrollto'

import "../../scss/app.scss";


$(document).ready(() => {
    $('.delete-devis').on('click', (event) => {
        event.preventDefault()
        let $elem = $(event.currentTarget)
        swal({
            title: "Etes-vous sûrs de vouloir supprimer ce devis ?",
            text: "Une fois supprimé vous ne pourrez plus y accéder !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    $.ajax($elem.attr('href')).done(() => {
                        swal("Votre devis a bien été supprimé !", {
                            icon: "success",
                        }).then(() => {
                            $elem.closest('tr').remove()
                        })
                    }).fail(() => {
                        swal("Il y a eu un problème lors de la suppression de votre devis enregistré !", {
                            icon: "error",
                        })
                    })

                }
            });
    })
})