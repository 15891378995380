import swal from 'sweetalert'

$(document).ready(function() {
    $('#formDevisInstantEtape1 #submitNew').on('click', (event) => {
        event.preventDefault();
        if ($('#nomLieu').val() != '') {
            $('#submitNew').closest('form').submit();
        }
        else {
            swal('Info manquante', 'Vous devez entrer un nom pour votre lieu de travaux', 'error');
        }
    })

    $('.link-show').on('click', function(event){
        event.preventDefault();
    })

    if($('[data-off-canvas]').length){
        $('body').on('click', function(event){
            $('[data-off-canvas]').foundation('close');
        })
    }

    if($('#formCommande1').length) {

        $('#submitCommande1').on('click', (event) => {
            event.preventDefault()
            let $form = $("#formCommande1");
            let msg = "";
            let adresse = $form.find('.inputAdresse').val()
            let cp = $form.find('.inputCp').val()
            let ville= $form.find('.inputVille').val()

            if(adresse == "") msg += "Vous devez saisir une adresse pour votre lieu de travaux.\n";
            if(cp == "") msg += "Vous devez saisir un code postal pour votre lieu de travaux.\n";
            if(ville == "") msg += "Vous devez saisir une ville pour votre lieu de travaux.\n";

            if(msg !== ""){
                swal({
                    title: 'Un petit problème',
                    text: msg,
                    icon : 'error',
                    className: "modal-error-devis"
                });
            }
            else {
                $form.submit();
            }

        })
    }

    if($('#formCommande3').length) {

        $('#submitCommande3').on('click', (event) => {
            event.preventDefault()
            let $form = $("#formCommande3")
            let msg = ""
            let $cgv = $form.find('.accept_cgv')

            if(!$cgv.is(':checked')) msg += "Vous devez accepter les CGV pour commander.\n";
            if (!$("input[name='type_paiement']:checked").val()) {
                msg += "Vous devez choisir un mode de paiement pour votre acompte.\n";
            }

            if(msg !== ""){
                swal({
                    title: 'Un petit problème',
                    text: msg,
                    icon : 'error',
                    className: "modal-error-devis"
                });
            }
            else {
                $form.submit();
            }
        })
    }
})
