import Foundation from 'foundation-sites';

$(document).ready(function() {
    // Foundation JavaScript
    // Documentation can be found at: http://foundation.zurb.com/docs
    Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a tabindex="0">Retour</a></li>';
    $(document).foundation();

    // Gérer les notifications
    $('.top-bar').find('form').find('input').on('click', function(event) {
        event.stopPropagation()
    })
});